@import url(https://fonts.googleapis.com/css?family=Poppins:100,300,400,500);
body {
  margin: 0;
  font-family: 'Poppins', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #26262d;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: .01em;
}

h1 {
  font-weight: 400;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

h3 {
  font-weight: 300;
  font-size: .75rem;
  -webkit-margin-before: 0;
          margin-block-start: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background-color: #f0f0f0;
  min-height: 100vh;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  color: #000000;
}
